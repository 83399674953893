import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { saveAsPng } from 'save-html-as-image';

const FormMeme = ({ randomMeme }) => {

	const [ topText, setTop ] = useState('') 
	const [ botText, setBot ] = useState('')
	const [ theFont, setFont ] = useState('Kalam') 
	const [ theFilter, setFilter ] = useState('none')
	const domImage = useRef()

	const downloadImg = () => {
		saveAsPng(domImage.current, { filename: 'conte-meme', printDate: true });
	}
	
	const changeFont = () => {
		theFont === 'Kalam' ? setFont('Dancing Script') : setFont('Kalam')
	}
	const changeFilter = () => {
		theFilter === 'hue' ? setFilter('none') : setFilter('hue')
	}

	return (
	<>
		<DivPrincipale>
			<RiquadroMeme ref={domImage} fontScelta={theFont}>
				<Immagine src={randomMeme} alt="meme-conte" Filtro={theFilter} />
				<h4 className="top">{topText}</h4>
				<h4 className="bottom">{botText}</h4>
			</RiquadroMeme>
			<FormWrapper>
				<form>
					<input name='topText' type="text" placeholder="Testo Sopra" value={topText}
						onChange={input => setTop(input.target.value)}
					/>
					<br/>
					<input name='topText' type="text" placeholder="Testo Sotto" value={botText}
						onChange={input => setBot(input.target.value)}
					/>
					<br/>
				</form>
				<div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginBottom: '2em' }}>
					<button onClick={changeFont} className="retro change">Change Font</button>
					<button onClick={changeFilter} className="retro change">Vaporwave?</button>
				</div>
			</FormWrapper>	
		</DivPrincipale>
		<button onClick={downloadImg} className="download retro">Download your Conte</button>
	</>
	)
}

const DivPrincipale = styled.div`
	@media (min-width: 600px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

const FormWrapper = styled.div`
	display: flex;
	margin: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;


	> form {
		> input {
			margin: 1em auto;
			font-family: "Press Start 2P";
			font-size: 14px;
			background-color: lightgray;
			border: 4px double rgba(0, 0, 0, 0.83);
			padding: 5px 8px 3px;
			
			&:focus {
				background-color: white;
			}
		}
	}

	@media (min-width: 845px) {
		min-width: 400px;
	}

`

const Immagine = styled.img`
	width: 100%;
	margin: 0;
	filter: ${props => props.Filtro === 'hue' ? 'hue-rotate(90deg) brightness(140%) contrast(150%)' : 'none'};
`

const RiquadroMeme = styled.div`
position: relative;
margin: auto;
height: 98%;
	> h4 {
	position: absolute;
	width: 100%;
	text-align: center;

	font-family: ${props => props.fontScelta}, cursive;
	font-size: 28px;
	@media (min-width: 600px) { font-size: 35px; }
	text-transform: uppercase;
	color: white;
	letter-spacing: 1px;
	word-spacing: 1px;
	text-shadow:
			2px 2px 0 #000,
			-2px -2px 0 #000,
			2px -2px 0 #000,
			-2px 2px 0 #000,
			0 2px 0 #000,
			2px 0 0 #000,
			0 -2px 0 #000,
			-2px 0 0 #000,
			2px 2px 5px #000;
	}

	> .bottom {
			bottom: 15px;
			margin-bottom: 0px;
	}

	> .top {
			top: 15px;
	}

`
export default FormMeme