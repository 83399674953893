import React, { useEffect, useState } from 'react'
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from "gatsby";

import FormMeme from '../../components/featured/formMeme'
import Header from '../../layouts/header'
import Footer from '../../layouts/footer'
import Seo from '../../components/seo/seo';

import { FaShareAlt } from 'react-icons/fa';
import { getSrc } from 'gatsby-plugin-image';

const CONTE_QUERY = graphql`query ConteQuery {
  allFile(filter: {relativeDirectory: {eq: "featured-pages/conte"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(quality: 95, width: 600, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
const MemeGen = ( { datos } ) => {

		const arrayMeme = datos.allFile.edges

		const [theMemes, setMemes] = useState([])
		const [mostra, setMostra] = useState(false)
		const [randomMeme, setRandomMeme] = useState('')

		useEffect(() => {
			setMemes(arrayMeme)
		}, [arrayMeme])
		
		const selectConte = () => {
			setMostra(true)
			const randNum = Math.floor(Math.random() * theMemes.length)
			const randomMem = getSrc( theMemes[randNum].node)
			setRandomMeme(randomMem)
		}
		const shareLink = function(){
			if (navigator.share) {
				navigator.share({
					title: `Conte Meme Generator`,
					text: `Generate your personal Conte Daddy ^-^ by @anonimoconiglio`,
					url: `https://www.anonimoconiglio.com/featured/conte-meme-generator`,
				})
					.then(() => console.log('Successful share'))
					.catch((error) => console.log('Error sharing', error));
			} else {
				navigator.clipboard.writeText(`Generate your personal Conte Daddy ^-^ \nby @anonimoconiglio: \nhttps://www.anonimoconiglio.com/featured/conte-meme-generator `)
					.then( () => alert('Link copiato nei tuoi appunti! \nIncollalo dove vuoi ;)'))
					.catch( error => alert(`Qualcosa è andato storto, per favore riporta l'errore al gestore del sito:`, error))
			}
		}

		return (
		<>
			<Seo 
					title="Conte Meme Generator" 
					description="Crea il tuo Conte Daddy personalizzato e fai vedere che sei una brava bimba" 
					slug="/featured/conte-meme-generator"
					ogImg="/img_og/conte-meme-generator-anonimoconiglio-preview.png"
			/>
			<Global styles={TheGlobalFonts} />
				<MainBody>
				<Header transparent ulmenuvisibile={false} />
				<MainFeatured>
					<h1>Conte Meme Generator</h1>
					<button onClick={selectConte} className='retro select'>Choose Your Conte</button>
					{mostra
						? <FormMeme randomMeme={randomMeme} />
						: <p className="clicca">Clicca per scoprire il tuo Conte</p>
					}
				</MainFeatured>
				<BottoneShare onClick={shareLink} >
					<FaShareAlt /> <span>Condividi questa pagina</span>
				</BottoneShare>
			</MainBody>
			<Footer />
		</>
		)}

const ConteMemeGenerator = () => (
	<StaticQuery
		query={CONTE_QUERY}
		render={ data => <MemeGen datos={data} /> }
	/>
)
export default ConteMemeGenerator

const MainBody = styled.div`
	background-color: #524763;
	background-image: url('/immagini-bkgrnd/conte-bkgr.svg');
	background-size: auto;
	background-attachment: fixed;
	padding-bottom: 2em;
`

const MainFeatured = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	min-height: 70vh;


	button.download, button.select {
			display: flex;
			justify-content: center;
	}
	button.change {
		font-size: 12px;
		justify-content: center;
	}
	button.retro {
			margin: 1em auto;
			font-family: "Press Start 2P";
			background: #FAEBD7;
			border-bottom: 6px outset rgba(0, 0, 0, 0.83);
			border-left: 6px outset #33333321;
			border-right: 6px outset rgba(0, 0, 0, 0.66);
			border-top: 6px outset #33333370;
			box-sizing: border-box;
			color: #333;
			cursor: url('/img_og/cursor.png') 10 3, auto !important;
			
			padding: .5rem;
			text-transform: uppercase;
			width: auto;

				&:focus,
				&:hover {
					background: #FFDEB3;
				}
				&:active {
					border-bottom: 6px inset #33333370;
					border-left: 6px inset rgba(0, 0, 0, 0.83);
					border-right: 6px inset #33333321;
					border-top: 6px inset rgba(0, 0, 0, 0.66);
				}
		}

	> h1 {
		font-family: 'Aladin', cursive;
		text-align: center;
		padding: 0.5em;
		margin-top: 2rem;
		font-size: 3rem;
		letter-spacing: 1px;
		color: #FAEBD7;
	}
	.clicca {
		font-family: 'Aladin', cursive;
		font-size: 2em;
		text-align: center;
		color: #FAEBD7;
	}
`

const BottoneShare = styled.button`
  display: block; 
  border: 2px dashed #FAEBD7; 
	cursor: url('/img_og/cursor.png') 10 3, auto !important;
  font-size: 1.3em; 
	color: #FAEBD7;
	background: transparent;
	margin: 1.5em auto 1em;

	span {
		vertical-align: middle;
	}
	svg {
		vertical-align: middle;
		font-size: 25px;
	}
`

const TheGlobalFonts = css`
				/* latin */
				@font-face {
					font-family: 'Aladin';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: url(https://fonts.gstatic.com/s/aladin/v9/ZgNSjPJFPrvJV5fF7i35.woff2) format('woff2');
					unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
				}
				/* latin */
				@font-face {
					font-family: 'Press Start 2P';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nVivM.woff2) format('woff2');
					unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
				}
				/* latin */
				@font-face {
					font-family: 'Dancing Script';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: url(https://fonts.gstatic.com/s/dancingscript/v15/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
					unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
				}
				/* latin */
				@font-face {
					font-family: 'Kalam';
					font-style: normal;
					font-weight: 400;
					font-display: swap;
					src: url(https://fonts.gstatic.com/s/kalam/v11/YA9dr0Wd4kDdMthROCc.woff2) format('woff2');
					unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
				}
				`
